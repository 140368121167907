import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Hr, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				El Sanatları Sanatı, Her Seferinde Tek Mürekkep
			</title>
			<meta name={"description"} content={"InkFusion Tattoo Studio'da, benzersiz tarzınıza ve tercihlerinize hitap eden çok çeşitli, yüksek kaliteli dövme hizmetleri sunmaktan gurur duyuyoruz."} />
			<meta property={"og:title"} content={"El Sanatları Sanatı, Her Seferinde Tek Mürekkep"} />
			<meta property={"og:description"} content={"InkFusion Tattoo Studio'da, benzersiz tarzınıza ve tercihlerinize hitap eden çok çeşitli, yüksek kaliteli dövme hizmetleri sunmaktan gurur duyuyoruz."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-4.jpg?v=2024-06-06T12:24:03.531Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-4.jpg?v=2024-06-06T12:24:03.531Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-4.jpg?v=2024-06-06T12:24:03.531Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-4.jpg?v=2024-06-06T12:24:03.531Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-4.jpg?v=2024-06-06T12:24:03.531Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-4.jpg?v=2024-06-06T12:24:03.531Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-4.jpg?v=2024-06-06T12:24:03.531Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header />
		<Section padding="60px 0 60px 0" md-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				align-self="center"
			>
				<Text
					font="--headline2"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					El Sanatları Sanatı, Her Seferinde Tek Mürekkep
				</Text>
				<Text
					color="--light"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					InkFusion Tattoo Studio'da, benzersiz tarzınıza ve tercihlerinize hitap eden çok çeşitli, yüksek kaliteli dövme hizmetleri sunmaktan gurur duyuyoruz. Deneyimli sanatçılarımız fikirlerinizi güzel, kalıcı sanata dönüştürmeye kendilerini adamıştır. Mükemmelliğe olan bağlılığımızla, her dövmenin sonsuza kadar değer vereceğiniz bir sanat eseri olmasını sağlıyoruz.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/1-4.jpg?v=2024-06-06T12:24:03.551Z"
					width="100%"
					max-height="100%"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					srcSet="https://smartuploads.quarkly.io/6661aa1f1d712a0023348f11/images/1-4.jpg?v=2024-06-06T12%3A24%3A03.551Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6661aa1f1d712a0023348f11/images/1-4.jpg?v=2024-06-06T12%3A24%3A03.551Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6661aa1f1d712a0023348f11/images/1-4.jpg?v=2024-06-06T12%3A24%3A03.551Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6661aa1f1d712a0023348f11/images/1-4.jpg?v=2024-06-06T12%3A24%3A03.551Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6661aa1f1d712a0023348f11/images/1-4.jpg?v=2024-06-06T12%3A24%3A03.551Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6661aa1f1d712a0023348f11/images/1-4.jpg?v=2024-06-06T12%3A24%3A03.551Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6661aa1f1d712a0023348f11/images/1-4.jpg?v=2024-06-06T12%3A24%3A03.551Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="30%"
				md-width="100%"
				font="--headline3"
			>
				<Text
					font="--headline3"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					Sanatçılarımızla Tanışın
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="70%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="100px 0px 0px 0px"
					color="--light"
					font="--headline5"
				>
					InkFusion Tattoo Studio'da sanatçılarımız kuruluşumuzun kalbi ve ruhudur. Her sanatçı, yarattığı her dövmenin gerçek bir şaheser olmasını sağlayarak, masaya yetenek, yaratıcılık ve tutkunun eşsiz bir karışımını getiriyor. Ekibimiz geleneksel ve neo-gelenekselden gerçekçi ve soyuta kadar çeşitli tarzlarda uzmanlaşmış deneyimli profesyonellerden oluşmaktadır. Dövme endüstrisindeki en son teknikler ve trendler konusunda daima güncel kalarak sürekli öğrenmeye ve gelişmeye adanmışlardır.
Sanatçılarımız dövmecilerden çok daha fazlasıdır; onlar fikirlerinizi ömür boyu gururla taşıyacağınız sanat eserlerine dönüştüren hikaye anlatıcılarıdır.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" margin="0px 0px 0px 0px" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" margin="0px auto 0px auto" />
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="30%"
				padding="0px 40px 0px 15px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
				lg-margin="0px 0px 60px 0px"
			>
				<Text margin="0px 0px 24px 0px" color="#ffffff" font="--headline2" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
					Dövme Tekliflerimiz
				</Text>
				<Text margin="0px 0px 1rem 0px" color="--lightD2" font="--base">
					InkFusion'da her ihtiyacınızı karşılayacak şekilde tasarlanmış kapsamlı bir dövme hizmetleri yelpazesi sunuyoruz. İster küçük, basit bir tasarım ister tam kollu bir tasarım arıyor olun, yetenekli sanatçılarımız bunu gerçekleştirecek beceriye ve yaratıcılığa sahiptir. İşte sunduklarımıza daha yakından bir bakış:
				</Text>
				<Text margin="auto 0px 0px 0px" color="--lightD2" font="--base">
					Ve bu sadece başlangıç. InkFusion Tattoo Studio'da tüm dövme ihtiyaçlarınızı karşılayacak geniş bir hizmet yelpazesi sunuyoruz. Aklınızda belirli bir tasarım olsun ya da ilhama ihtiyacınız olsun, yetenekli sanatçılarımız sürecin her adımında size rehberlik etmek için buradalar. Sunduğumuz tüm seçenekleri keşfetmek ve sizin için mükemmel dövmeyi keşfetmek için bugün bizimle iletişime geçin veya stüdyomuzu ziyaret edin.
				</Text>
			</Box>
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="68%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
			>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Özel Dövme Tasarımları
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Özel dövme tasarımlarımız kişiliğinizi ve vizyonunuzu yansıtacak şekilde özel olarak tasarlanmıştır.{" "}
							<br />
							Hikayenizi anlatan benzersiz bir sanat eseri yaratmak için sizinle yakın işbirliği içinde çalışıyoruz.{"\n"}
							<br />
							Kişisel Danışmanlık: Sanatçılarımız fikirlerinizi ve tercihlerinizi anlamak için sizinle birlikte oturur ve nihai tasarımın tam olarak hayal ettiğiniz gibi olmasını sağlar.{"\n"}
							<br />
							Eskiz Geliştirme: Girdiğiniz bilgilere dayanarak konseptinizi hayata geçiren ayrıntılı eskizler oluştururuz.{"\n"}
							<br />
							Nihai Tasarım Onayı: Dövme işlemine geçmeden önce eskizleri inceliyor ve onayınızı alıyoruz.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Siyah ve Gri Dövmeler
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Siyah ve gri dövmelerin klasik zarafetini takdir edenler için, yetenekli sanatçılarımız bu zamansız tarzda ustalaşıyor.
Gölgeleme Teknikleri: Dövmenizde derinlik ve gerçekçilik yaratmak için uzman gölgeleme teknikleri.{"\n"}
							<br />
							Karmaşık Ayrıntılar: Dövmenizi öne çıkaran ince çizgiler ve karmaşık ayrıntılar.{"\n"}
							<br />
							Çok Yönlü Tasarımlar: Portreler, manzaralar ve soyut sanatlar için uygundur.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Renkli Dövmeler
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Hassasiyet ve yaratıcılıkla hazırlanmış çarpıcı renkli dövmelerimizle vücut sanatınıza canlılık katın.{"\n"}
							<br />
							Cesur Renkler: Uzun ömürlü ve canlı renkler sağlayan yüksek kaliteli mürekkeplerin kullanılması.{"\n"}
							<br />
							Renk Karışımı: Doğal bir görünüm için renkleri kusursuz bir şekilde karıştırma teknikleri.{"\n"}
							<br />
							Özel Paletler: Tasarımınıza uyacak kişiselleştirilmiş renk paletleri.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Kapatılmış Dövmeler
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Uzman kaplama hizmetlerimizle eski dövmelerinizi dönüştürün veya yenileyin, size yeni bir tasarımla yeni bir başlangıç ​​yapın.
Yaratıcı Çözümler: Mevcut dövmelerin etkili bir şekilde kapatılmasına yönelik yenilikçi yaklaşımlar.{"\n"}
							<br />
							Katmanlama Teknikleri: Eski dövmeleri maskelemek için katmanlama ve gölgeleme kullanımı.{"\n"}
							<br />
							Kişiselleştirilmiş Tasarımlar: Örtüyü tamamlayan ve geliştiren özel tasarımlar.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Küçük ve Minimalist Dövmeler
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							İnce ve zarif bir dokunuş için küçük ve minimalist dövmelerimiz, sadeliği ifade etmek için mükemmeldir.{"\n"}
							<br />
							Hassas Çizgiler: İnce çizgiler ve küçük ayrıntılar oluşturmada hassasiyet.
							<br />
							{"\n"}Zarif Tasarımlar: Derin anlam taşıyan minimalist tasarımlar.
Hızlı Seanslar: Hızlı ve ağrısız bir deneyim arayanlar için idealdir.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Dövme Sonrası Bakım
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Dövmenizin düzgün bir şekilde iyileşmesini sağlamak çok önemlidir ve kapsamlı bakım sonrası rehberliğimiz en iyi sonuçları elde etmenize yardımcı olur.{"\n"}
							<br />
							Kişiselleştirilmiş Talimatlar: Dövmenize özel olarak hazırlanmış ayrıntılı bakım sonrası talimatlar.
							<br />
							{"\n"}Yüksek Kaliteli Ürünler: İyileşmeye yardımcı olacak en iyi ürünlere ilişkin öneriler.{"\n"}
							<br />
							Sürekli Destek: İyileşme süreci sırasında her türlü soru veya endişeyi gidermek için sürekli destek.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 0px 0px" />
			</Box>
		</Section>
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-1.jpg?v=2024-06-06T12:24:03.555Z) center center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Vizyonunuzu Gerçeğe Dönüştürmeye Hazır mısınız?
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					InkFusion Tattoo Studio'yu ziyaret ederek yeni dövmenize doğru ilk adımı atın. Yetenekli sanatçılarımız sizinle çalışmaktan ve ömür boyu gururla taşıyacağınız bir sanat eseri yaratmaktan heyecan duyuyor. Randevunuzu bugün alın ve her seferinde bir mürekkep kullanarak sanat eserleri oluşturmanıza yardımcı olalım.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--dark"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Kişiler
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});